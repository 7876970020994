import React from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import { BgImg, Section, Container, Cols, Col } from '@elwell/blocko';
import useViewportSizes from 'use-viewport-sizes';

import { vars, IconArrowLeft, IconArrowRight } from '../lib/theme';

import TestimonialText from './TestimonialText';
import TestimonialVideo from './TestimonialVideo';
import Button from './Button';

const testimonials = [
  {
    type: 'text',
    text: 'Thomas McRocklin is the Mozart of guitar.',
    name: 'Steve Vai',
    position: 'Guitarist',
    avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: 'I have been playing guitar for 3 years and I have tried JamPlay, Truefire, YouTube... but the progress I have made in 1 month here with your lessons is unbelievable.',
    name: 'Nikos',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text:
      'Thomas is not only a gifted guitarist but he is also a very talented teacher! It is one thing to be a great player but a great player who can explain complicated concepts and make them simple is a real joy!',
    name: 'Tony',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text:
      "I've played guitar for about 20 years, always been ok at it, can pull out a solo here and there, but the little things in the lessons that you don't realise help so much!",
    name: '@obs0120',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: 'It is such a good feeling when there is a click and sudden step forward in playing. School of McRock is a plataeu killer! 🏆',
    // name: '@GypsyCat',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: "I've pushed further in the last year than in the last 20! I have a completely different approach to the instrument. 🙌 Check it out. 😊",
    name: 'Stuart',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: "I am currently enrolled in your lessons and I am playing stuff I never thought I'd be able to play. Thank you 🙏",
    name: '@baristart',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: "Just wanted to say that your School of McRock lessons have been some of the most fun I've had practicing in a long time!",
    // name: '@baristart',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: "Since I joined I've actually started wearing down picks! Something I've never done in the past so I know I'm playing a lot more these days",
    name: 'Richard',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },

  {
    type: 'text',
    text: "I've been getting used to my amp again, and holy hell!! I can play things a hell of a lot faster from when I left. I'm in love with guitar again!",
    name: 'Andrew',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: 'Your heavy rhythmn lesson is awesome! It made me realize how invisible (beat 4) was for me. I love the speed adjustment feature, I use it a ton. 🤘 Thanks Thomas! ❤️',
    // name: 'Richard',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },

  {
    type: 'text',
    text:
      'I record myself playing and listening back to some of my old improvisations is like hearing another person. My playing now is more solid and its the first time I am playing like I really mean it. Thank you so much!',
    name: 'Nikos',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: "Dude... I haven't practiced like that in ages... if I knew the angle of my damn pick was the cause of my struggles... I would've flipped my s**t 😅 Loving the lessons!",
    // name: 'Nikos',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },

  {
    type: 'text',
    text: "Great masterclass. I'm always so jazzed to try new things after them. Thanks McRocklin!",
    name: 'Richard',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },

  {
    type: 'text',
    text: 'Thomas gives you the exact tips we all should have learned 20yrs before',
    name: 'Mallito',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: "I just checked out the 'Unlocking The Fretboard' lesson and holy cow dude! I'm blown away",
    name: 'Brian',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: "Hey McRocklin, just got a subscription to School of McRock for my birthday and I'm loving it! Keep it up! :)",
    // name: '@baristart',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: 'I started Rhythm 101 lesson and I found many little details that make a lot of difference!',
    name: 'Nikos',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: 'My little noodle arm is killing after that speed lesson but I have improved so much 🤘🤘',
    // name: '@baristart',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },

  {
    type: 'text',
    text:
      "I sat down for about an hour and practiced your lesson on how to play fast. I love you how piece the lessons/exercises together little by little! I'm going to stick with this! 🤘",
    // name: '@baristart',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },

  {
    type: 'text',
    text: 'I’m really enjoying the School of McRock content, the structure of the lessons is brilliant.',
    // name: 'Steve Vai',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: 'I love School of McRock, definitely a game changer!',
    // name: 'Steve Vai',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: 'I’m really enjoying the School of McRock content, the structure of the lessons is brilliant.',
    // name: 'Steve Vai',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text:
      'I really like School of McRock and you have been an inspiration to me on my quest to play more deftly with greater speed. I really like your lessons that provide drills to hone the techniques that you demonstrate.',
    // name: 'Steve Vai',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: 'Thanks for all the amazing content, so stoked you are doing these lessons.',
    // name: 'Steve Vai',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: 'Thanks for doing the online classes they are doing wonders to my playing!',
    // name: 'Steve Vai',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: 'Love the site and all of the lessons have been really helpful',
    // name: 'Steve Vai',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: 'I really admire your talent but mostly your hard work. I can’t wait to find out more about your picking techniques and all other crazy secrets.',
    // name: 'Steve Vai',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: "There's already so much in those lessons that I'm finding myself trying to get better organized with my practice time to fit more of your stuff in!",
    // name: 'Steve Vai',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  {
    type: 'text',
    text: 'Thanks for your support and your amazing lessons, you’re a massive inspiration to me and I hope I can figure this guitar maze out step by step with your help :)',
    // name: 'Steve Vai',
    position: 'SoM Student',
    // avatarUrl: '/static/avatar-vai.jpg',
  },
  // {
  //   type: 'video',
  //   src: '/static/testimonials/rb-1.mp4',
  //   name: 'Robin',
  //   position: 'SoM Student',
  // },
  // {
  //   type: 'video',
  //   src: '/static/testimonials/ag.mov',
  //   name: 'Andrew',
  //   position: 'SoM Student',
  // },
  // {
  //   type: 'video',
  //   src: '/static/testimonials/tg.mov',
  //   name: 'Tony',
  //   position: 'SoM Student',
  // },
];

const SectionTestimonials = ({ children }) => {
  const [carouselAutoplay, setCarouselAutoplay] = React.useState(true);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [slidesToShow, setSlidesToShow] = React.useState(1);
  const [vpWidth, vpHeight, updateVpSizes] = useViewportSizes();

  React.useEffect(() => {
    updateVpSizes();
  }, []);
  React.useEffect(() => {
    let s = 1;
    if (vpWidth > 700) s = 2;
    if (vpWidth > 1200) s = 3;
    setSlidesToShow(s);
  }, [vpWidth]);

  const afterSlide = (props) => {
    setCurrentIndex(props);
  };
  const handleSlideClick = (idx) => {
    setCurrentIndex(idx);
    setCarouselAutoplay(false);
  };

  return (
    <Section space={1} bg={vars.colors.secondary.darker}>
      {/* <BgImg
        css={css`
          opacity: 0.75;
        `}
        src="/static/bg-stars.jpg"
      /> */}
      <Container>
        <Container space={1}>
          <H3>
            What people are saying about
            <img
              css={css`
                width: ${vars.break.xs};
                margin: ${vars.spacing[1]} auto 0 auto;
              `}
              src="/static/logo-school-of-mcrock.svg"
              alt="School of McRock"
            />
          </H3>
        </Container>
        <CarouselWrap>
          <Carousel
            slideIndex={currentIndex}
            slidesToShow={slidesToShow}
            wrapAround
            cellAlign="center"
            autoplay={carouselAutoplay}
            transitionMode="scroll3d"
            opacityScale={0.33}
            afterSlide={afterSlide}
            // withoutControls
            renderBottomCenterControls={null}
            renderCenterLeftControls={({ previousSlide }) => (
              <Button theme="textOnly" onClick={previousSlide}>
                <IconArrowLeft style={{ color: vars.colors.white }} size={40} />
              </Button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <Button theme="textOnly" onClick={nextSlide}>
                <IconArrowRight style={{ color: vars.colors.white }} size={40} />
              </Button>
            )}
          >
            {testimonials.map(({ type, text, name, position, avatarUrl, src }, idx) => (
              <Slide key={`testimonials-slide-${idx}`} onClick={() => handleSlideClick(idx)}>
                {type == 'text' && <TestimonialText text={text} name={name} position={position} avatarUrl={avatarUrl} />}
                {type == 'video' && <TestimonialVideo playing={currentIndex == idx ? true : false} src={src} name={name} position={position} />}
              </Slide>
            ))}
          </Carousel>
        </CarouselWrap>
      </Container>
    </Section>
  );
};

export default SectionTestimonials;

// Styles

const H3 = styled('h3')`
  color: ${vars.colors.white};
  text-align: center;
`;

const Slide = styled('div')`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 ${vars.spacing[3]};
  position: relative;
`;

const CarouselWrap = styled('div')`
  margin-top: ${vars.spacing[3]};
  .slider-slide {
    outline: none;
    display: flex !important;
    align-items: center;
    height: 100%;
  }
`;

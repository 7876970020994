import React from 'react';
import styled from 'styled-components';

import { vars } from '../lib/theme';
import { Container } from '../lib/styles';

const SectionHomeUsps = () => {
  return (
    <Section>
      <Container>
        <h3
          css={css`
            text-align: center;
            margin-bottom: ${vars.spacing[4]};
          `}
        >
          Membership includes:
        </h3>
        <Inner>
          <UspWrap>
            <Usp>
              <img src="/static/icon-lightning.svg" alt="Lightning icon" />
              <div>
                <h4>Fun guitar lessons</h4>
                <p>Our lessons cover a huge range of topics and are jam packed with tips and insights that you won't find anywhere else.</p>
              </div>
            </Usp>
          </UspWrap>
          <UspWrap>
            <Usp>
              <img src="/static/icon-lightning.svg" alt="Lightning icon" />
              <div>
                <h4>Live Q&A Sessions</h4>
                <p>Every week we host live masterclasses where members can ask Thomas questions and get advice on their guitar playing.</p>
              </div>
            </Usp>
          </UspWrap>
          <UspWrap>
            <Usp>
              <img src="/static/icon-lightning.svg" alt="Lightning icon" />
              <div>
                <h4>Huge backing track library</h4>
                <p>We release exclusive, studio-quality backing tracks every week for you to jam along to our download and remix.</p>
              </div>
            </Usp>
          </UspWrap>
          <UspWrap>
            <Usp>
              <img src="/static/icon-lightning.svg" alt="Lightning icon" />
              <div>
                <h4>Regular new content</h4>
                <p>New content is recorded and launched every single week, so there's always something new to get your teeth stuck into.</p>
              </div>
            </Usp>
          </UspWrap>
          <UspWrap>
            <Usp>
              <img src="/static/icon-lightning.svg" alt="Lightning icon" />
              <div>
                <h4>Members chat room</h4>
                <p>Join in our exclusive chat room and hang out with Thomas and our community of friendly, awesome guitarists!</p>
              </div>
            </Usp>
          </UspWrap>
          <UspWrap>
            <Usp>
              <img src="/static/icon-lightning.svg" alt="Lightning icon" />
              <div>
                <h4>Giveaways and competitions</h4>
                <p>Join in monthly competitions and access our exclusive giveaways and gear promotions from big name brands.</p>
              </div>
            </Usp>
          </UspWrap>
          {/* <Link href="/join">
          <a>
            <Button>Get started</Button>
            </a>
        </Link> */}
        </Inner>
      </Container>
    </Section>
  );
};

export default SectionHomeUsps;

// Styles

const Section = styled('section')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: ${vars.spacing[4]} 0;
  background-color: ${vars.colors.grey.offwhite};
`;

const Inner = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -2%;
`;

const UspWrap = styled('div')`
  padding: 2%;
  flex: 0 0 100%;
  margin-bottom: ${vars.spacing[2]};

  @media screen and (min-width: ${vars.break.md}) {
    flex: 0 0 50%;
    margin-bottom: 0;

  }
  /* @media screen and (min-width: ${vars.break.lg}) {
    flex: 0 0 33.333%;
  } */
`;

const Usp = styled('div')`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  h4 {
    margin-bottom: ${vars.spacing[0]};
  }
  img {
    width: 40px;
    flex: 0 0 40px;
    margin-bottom: 0;
    margin-right: ${vars.spacing[1]};
  }
`;

import React from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import styled from 'styled-components';

import { vars } from '../lib/theme';
import { Container } from '../lib/styles';

import useUser from './useUser';

import Button from './Button';
import LoadingSpinner from './LoadingSpinner';

const VideoPlayer = dynamic(() => import('./VideoPlayer'), { ssr: false, loading: () => <LoadingSpinner /> });

const SectionHomeUsps = () => {
  const user = useUser();

  return (
    <Section>
      <Container>
        <h2
          css={css`
            text-align: center;
            margin-bottom: ${vars.spacing[4]};
          `}
        >
          Forget everything you know about <GradText>online guitar lessons</GradText>.
        </h2>
        <Inner>
          <InnerVideo>
            <Video>
              <VideoPlayer videoType="youtube" videoId="p74krynuFSA" />
            </Video>
          </InnerVideo>
          <InnerText>
            <Usp>
              <h3>Break out of the rut</h3>
              <p>Stop playing the same licks over and over. Unlock your potential and become a better guitar player. You won't regret it.</p>
            </Usp>
            <Usp>
              <h3>Get real insights</h3>
              <p>Our lessons cover a huge range of topics and are jam packed with tips and insights that you won't find anywhere else.</p>
            </Usp>
            <Usp>
              <h3>Smash your goals</h3>
              <p>Never get stuck again. Receive support directly from pro guitarists in our live Q&A sessions and members chatroom.</p>
            </Usp>
          </InnerText>
        </Inner>
        {!user?.hasHadTrial && (
          <Link href="/join">
            <a>
              <Button
                css={css`
                  margin-top: ${vars.spacing[2]};
                  font-size: 1.6rem;
                `}
                theme="success"
                block
              >
                <div>
                  Start now and get 14 days FREE
                  <div
                    css={css`
                      color: ${vars.colors.success.offwhite};
                      font-size: 1rem;
                      font-weight: 400;
                      font-family: ${vars.fonts.body};
                    `}
                  >
                    Improve your guitar playing today
                  </div>
                </div>
              </Button>
            </a>
          </Link>
        )}
      </Container>
    </Section>
  );
};

export default SectionHomeUsps;

// Styles

const Section = styled('section')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: ${vars.spacing[4]} 0;
`;

const Inner = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InnerVideo = styled('div')`
  flex: 0 0 100%;
  margin-bottom: ${vars.spacing[3]};
  @media screen and (min-width: ${vars.break.md}) {
    flex: 0 0 50%;
    margin-bottom: 0;
    padding-right: 2%;
  }
`;
const InnerText = styled('div')`
  flex: 0 0 100%;
  margin-bottom: ${vars.spacing[2]};
  @media screen and (min-width: ${vars.break.md}) {
    flex: 0 0 50%;
    margin-bottom: 0;
    padding-left: 2%;
  }
`;

const Usp = styled('div')`
  text-align: left;
  margin-bottom: ${vars.spacing[2]};
  h3 {
    margin-bottom: ${vars.spacing[0]};
  }
  p {
    margin: 0 auto;
  }
`;

const Video = styled('div')`
  border-radius: ${vars.borderRadius[1]};
  overflow: hidden;
  box-shadow: ${vars.shadows[0]};
`;

const GradText = styled('span')`
  color: ${vars.colors.primary.light};
  background: -webkit-linear-gradient(40deg, ${vars.colors.tertiary.mid} 15%, ${vars.colors.primary.mid} 85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

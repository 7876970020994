import React from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import { vars } from '../lib/theme';
// import { Container } from '../lib/styles';
import { BgImg, Section, Container, Cols, Col } from '@elwell/blocko';

import useUser from './useUser';

import Button from './Button';

const SectionCta = ({ theme, heading, body, link, buttonText }) => {
  const user = useUser();

  // hide this component from subscribers
  if (user?.hasHadTrial) return null;
  return (
    <Section space={1} bg={vars.colors.secondary.darkest}>
      <BgImg
        css={css`
          opacity: 0.75;
        `}
        src="/static/bg-stars.jpg"
      />
      <Container width={vars.break.md} textAlign="center" space={1}>
        {heading && (
          <h3
            css={css`
              color: ${vars.colors.white};
            `}
          >
            {heading}
          </h3>
        )}
        {body && (
          <p
            css={css`
              color: ${vars.colors.white};
              margin-bottom: ${vars.spacing[3]};
            `}
          >
            {body}
          </p>
        )}
        {link && (
          <Link href={link}>
            <a>
              <Button theme="success">{buttonText}</Button>
            </a>
          </Link>
        )}
      </Container>
    </Section>
  );
};

export default SectionCta;

import React from 'react';
import styled from 'styled-components';

import { vars, IconMute, IconUnMute } from '../lib/theme';

import Button from './Button';

const TestimonialVideo = ({ src, name, position, playing }) => {
  const [muted, setMuted] = React.useState(true);
  const videoRef = React.useRef(null);
  React.useEffect(() => {
    if (playing) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [playing]);
  return (
    <VideoWrap>
      <VideoBg>
        <Overlay />
        <video ref={videoRef} loop={true} muted={muted} autoPlay={playing} playsInline={true}>
          <source src={src} type="video/mp4" />
        </video>
      </VideoBg>
      <Deets>
        {name && <h5>{name}</h5>}
        {position && <h6>{position}</h6>}
      </Deets>
      <Button
        css={css`
          position: absolute;
          bottom: ${vars.spacing[2]};
          right: ${vars.spacing[2]};
          color: ${vars.colors.white};
        `}
        theme="textOnly"
        onClick={() => setMuted((prev) => !prev)}
      >
        {muted ? <IconMute size={40} /> : <IconUnMute size={40} />}
      </Button>
    </VideoWrap>
  );
};

export default TestimonialVideo;

// Styles

const VideoBg = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${vars.colors.black};
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const Overlay = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(15deg, rgba(0, 0, 0, 0.65) 15%, rgba(0, 0, 0, 0) 40%);
`;

const VideoWrap = styled('div')`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 130%;
  overflow: hidden;
  box-shadow: ${vars.shadows[0]};
  border-radius: ${vars.borderRadius[2]};
`;

const Deets = styled('div')`
  position: absolute;
  bottom: ${vars.spacing[2]};
  left: ${vars.spacing[2]};
  h5 {
    margin: 0;
    color: ${vars.colors.white};
  }
  h6 {
    color: ${vars.colors.primary.lighter};
  }
`;

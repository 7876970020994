import React from 'react';
import styled from 'styled-components';

import { vars, IconLdquo } from '../lib/theme';

import Avatar from './Avatar';

const TestimonialText = ({ text, name, position, avatarUrl }) => {
  return (
    <Root>
      <Quote>
        <Ldquo>
          <IconLdquo size={40} />
        </Ldquo>
        {text}&rdquo;
      </Quote>
      <Avatar
        url={avatarUrl}
        css={css`
          margin: 0 auto ${vars.spacing[1]} auto;
          border: 4px solid ${vars.colors.primary.mid};
        `}
        size={80}
      />
      {name && (
        <h5
          css={css`
            text-align: center;
            margin: 0;
          `}
        >
          {name}
        </h5>
      )}
      {position && (
        <h6
          css={css`
            text-align: center;
            margin: 0;
            color: ${vars.colors.primary.lighter};
          `}
        >
          {position}
        </h6>
      )}
    </Root>
  );
};

export default TestimonialText;

// Styles

const Root = styled('div')`
  padding: ${vars.spacing[3]};
  box-shadow: ${vars.shadows[0]};
  border-radius: ${vars.borderRadius[2]};
  background-color: ${vars.colors.white};
`;

const Quote = styled('blockquote')`
  margin: 0;
  /* font-size: 1.2rem; */
  /* font-style: italic; */
  position: relative;
  text-align: center;
  font-weight: 600;
  margin-bottom: ${vars.spacing[2]};
`;

const Rating = styled('div')`
  display: block;
  margin-bottom: ${vars.spacing[2]};
  color: ${vars.colors.gold};
  text-align: center;
`;

const Ldquo = styled('span')`
  position: absolute;
  top: -1.4em;
  left: -1.4em;
  /* z-index: -1; */
  opacity: 0.1;
`;
